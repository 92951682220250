import React, { useState } from "react";
import Expense from "./components/Expense/Expense";
import "./components/Expense/Expense.css";
import NewExpense from "./components/NewExpense/NewExpense";
const DUMMY_EXPENSES = [
  {
    id: 1,
    date: new Date(2022, 3, 19),
    title: "Paint home",
    amount: 100000,
  },
  {
    id: 2,
    date: new Date(2020, 8, 30),
    title: "Books",
    amount: 500,
  },
  {
    id: 3,
    date: new Date(2018, 5, 1),
    title: "Furniture",
    amount: 20000,
  },
  {
    id: 4,
    date: new Date(2019, 0, 2),
    title: "Computer",
    amount: 50500,
  },
];

function App() {
  const [expenses, setExpenses] = useState(DUMMY_EXPENSES);
  const addExpenseHandler = (expense) => {
    setExpenses(prevExpenses => {
      return [expense, ...prevExpenses]
    })
  };

  return (
    <div>
      <NewExpense onAddExpense={addExpenseHandler} />
      <Expense items={expenses} />
    </div>
  );
}

export default App;
